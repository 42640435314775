import LogoFSIVUT from "../images/reference/fsivut/logofsi.png"
import LogoHelcel from "../images/reference/helcel/logohelcel.png"
import LogoIFE from "../images/reference/ife/logoife.png"
import LogoJena from "../images/reference/jena/logojena.png"
import LogoKiwi from "../images/reference/kiwi/logo.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"

export const LIST_REFERENCES = [
  {
    image: LogoKiwi,
    alt: "logo kiwi.com",
    link: "/marketing/reference/kiwi",
  },
  {
    image: LogoFSIVUT,
    alt: "logo fakulty strojního inženýrství",
    link: "/marketing/reference/fsivut",
  },
  {
    image: LogoHelcel,
    alt: "logo Helcel",
    link: "/marketing/reference/helcel",
  },
  {
    image: LogoIFE,
    alt: "logo IFE",
    link: "/marketing/reference/ife",
  },
  {
    image: LogoJena,
    alt: "logo Jena nábytek",
    link: "/marketing/reference/jena",
  },
  {
    image: LogoSZ,
    alt: "logo Snowboard Zezula",
    link: "/marketing/reference/snowboard-zezula",
  },
]
