import React from "react"
import Layout from "../components/layout"
import {
  H2,
  Link,
  ReferencesWithoutBorder,
  Banner,
  SectionWrapper,
  SectionWrapperBackgroundStyle,
  IconTextIntroductionSection,
  InnerFloeContainer,
  TopBottomFloeWrapper,
  BannerStyle,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"
import BlogSection from "../components/blog-section"

import { useRssBlogFeed } from "../hooks/use-rss-blog-feed"
import { LIST_REFERENCES } from "../model/list-references-hp"
import { ICON_TEXT_INTRODUCTION_INDEX } from "../model/icon-text-introduction-index"
import { Container } from "reactstrap"
import ReferenceImageText from "../components/reference-detail/reference-image-text"
import AA from "../images/HP/learning_penguins.svg"
import { CERTIFICATES } from "../model/certificates"

const IndexPage: React.FC = () => {
  const rss = useRssBlogFeed()
  return (
    <Layout>
      <Banner
        style={BannerStyle.HP}
        orangeHeading="Jediná firma,"
        blackHeading="kterou budete v&nbsp;on&#8209;line</br>světě potřebovat"
        text="Nejsme továrna, pracujeme raději pro menší množství klientů pořádně. Nebudeme se na vás učit, naši specialisté mají průměrně 6&nbsp;let zkušeností v&nbsp;oboru."
      />

      <TopBottomFloeWrapper>
        <InnerFloeContainer>
          <IconTextIntroductionSection
            darkBG
            negativeMargin
            heading="Co pro vás zařídíme"
            data={ICON_TEXT_INTRODUCTION_INDEX}
          />
        </InnerFloeContainer>
      </TopBottomFloeWrapper>

      <Container>
        <ReferenceImageText
          right
          img={AA}
          alt=""
          width="600"
          height="666"
          svgImage
        >
          <h2>Analytics Academy</h2>
          <p className="mt-3 mb-5" style={{ lineHeight: "2rem" }}>
            Analytics Academy je primárně pro firmy&nbsp;–&nbsp;ať už interní
            týmy, nebo agentury. Máte analytický tým a&nbsp;chcete, aby byl na
            GA4 připravený a&nbsp;nedošlo ke zbytečným fuckupům. Ať už na úrovni
            implementace, nebo práce s&nbsp;daty.
          </p>
          <p>
            <Button to="//analytics-academy.cz" style={ButtonStyle.Primary}>
              Mrknout na Analytics Academy
            </Button>
          </p>
        </ReferenceImageText>
      </Container>

      <SectionWrapper>
        <H2>Naše znalosti jsou prověřené</H2>
        <ReferencesWithoutBorder
          certificates
          anotherReferences={CERTIFICATES}
        />
      </SectionWrapper>

      <SectionWrapper backgroundStyle={SectionWrapperBackgroundStyle.PrimaryBG}>
        <H2>Můžete se na nás spolehnout</H2>
        <ReferencesWithoutBorder anotherReferences={LIST_REFERENCES} />
        <div className="d-flex justify-content-center mt-3">
          <Link to="/reference">Další reference</Link>
        </div>
      </SectionWrapper>

      <BlogSection rss={rss} />
    </Layout>
  )
}

export default IndexPage
