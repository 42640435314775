import BadgeGuru from "../images/HP/badge-guru.png"
import BadgePartner from "../images/HP/badge-partner.png"
import BadgeSklik from "../images/HP/badge-sklik.png"
import BadgeTrainer from "../images/HP/badge-trainer.png"
import HeurekaPartner from "../images/HP/heureka-partner.png"
import BadgeBeed from "../images/HP/badge-beed.png"
import BadgeFB from "../images/HP/badge-fb-api-accelerator.jpeg"

export const CERTIFICATES = [
  {
    image: BadgePartner,
    alt: "Premier Google Partner",
    link: "//www.google.com/partners/agency?id=4359599149",
  },
  {
    image: BadgeTrainer,
    alt: "Google Partners Trainer",
    noHoverEffect: true,
  },
  {
    image: HeurekaPartner,
    alt: "Heureka Partner",
    noHoverEffect: true,
  },
  {
    image: BadgeGuru,
    alt: "AdWords guru 2018",
    link: "//drive.google.com/file/d/1dVtOB6F4jG33DpTDyn6kYdnd5wj9L7rc/view",
  },
  {
    image: BadgeSklik,
    alt: "Sklik.cz a Zboží.cz ověření",
    noHoverEffect: true,
  },
  {
    image: BadgeBeed,
    alt: "logo aplikace Beed",
    noHoverEffect: true,
  },
  {
    image: BadgeFB,
    alt: "logo facebook Marketing API Accelerator Winner",
    noHoverEffect: true,
  },
]
