import Screens from "../images/hosting/screens.svg"
import ThumbsUp from "../images/HP/thumbs_up_heart.svg"
import Wireless from "../images/HP/wireless.svg"

export const ICON_TEXT_INTRODUCTION_INDEX = [
  {
    href: "/vyvoj",
    heading: "Web nebo e-shop",
    text: [
      "Vytvoříme úplně nový, nebo jen vylepšíme ten stávající. Stavíme při tom na našich znalostech, vašich zkušenostech a\u00A0tvrdých datech z uživatelských výzkumů a\u00A0testování.",
    ],
    src: Screens,
    alt: "",
  },
  {
    href: "/marketing",
    heading: "Online marketing",
    text: [
      "Kdo chce okamžitý výkon, tomu nastartujeme PPC, zbožáky, Facebook reklamu. Kdo vytrvá, s\u00A0tím se pustíme do marketingové strategie, e‑mailingu i\u00A0SEO. Buď vše zařídíme my, nebo jako mentoři povedeme vaše lidi.",
    ],
    src: ThumbsUp,
    alt: "",
  },
  {
    href: "/hosting",
    heading: "Hosting",
    text: [
      "Už nemusíte přemýšlet, zda pořídit virtuální, dedikovaný nebo cloudový server. My vám hosting přizpůsobíme tak, aby web či e‑shop parádně šlapal\u00A0–\u00A0a\u00A0abyste neplatili zbytečné peníze navíc.",
    ],
    src: Wireless,
    alt: "",
  },
]
