import { H2, H2Style, Button, ButtonStyle } from "@igloonet-web/shared-ui"

import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import TextTruncate from "react-text-truncate"

import PenguinHead from "../icons/penguin_head.svg"
import ChattingPenguins from "../images/HP/chatting_penguins.svg"
import ChattingPenguinsMobile from "../images/HP/mobile/chatting_penguins_mobile.svg"

type BlogSectionProps = {
  rss: Array<{
    link: string
    title: string
    creator: string
    content: string
  }>
}

const HeadingWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    z-index: -1;
    margin-top: -10%;
    padding: 23% 0;
    background: url(${ChattingPenguins}) no-repeat left bottom;
    background-size: contain;
  }

  h2,
  p {
    margin: 0 auto;
  }
`
const Heading = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    margin: auto;
    padding-left: 40%;
    max-width: 1500px;
  }

  p {
    line-height: 2rem;
  }
`

const BlogWrapper = styled(Col)`
  margin-top: -4rem;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    margin-top: -10rem;
  }
  &:before {
    display: block;
    content: "";
    padding-bottom: 100%;
    width: 100%;
    background-image: url(${ChattingPenguinsMobile});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    z-index: -10;

    @media (min-width: ${(props) => props.theme.sizes_min.md}) {
      content: normal;
    }
  }
`

const BlogWrapperContent = styled.div`
  background-color: ${(props) => props.theme.color.beige300};
`

const BlogInnerWrapper = styled(Col)`
  &:nth-child(2),
  &:nth-child(3) {
    display: none;

    @media (min-width: ${(props) => props.theme.sizes_min.md}) {
      display: flex;
    }
  }
`

const RssBlogInner = styled.div`
  height: 100%;

  h3 {
    margin-bottom: 5px;
  }

  p {
    flex-grow: 1;
  }
`

const RssBlogTitle = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    min-height: 85px;
  }
`

const Text = styled(TextTruncate)`
  line-height: 2rem;
`

const CustomAnchor = styled.a`
  text-decoration: underline;
`

const BlogSection: React.FC<BlogSectionProps> = ({ rss }) => {
  return (
    <div className="d-md-flex flex-column pt-5 pt-md-0">
      <HeadingWrapper>
        <Heading>
          <H2 style={H2Style.Orange} className="w-75 text-md-left">
            Zůstaňte s&nbsp;námi v&nbsp;obraze
          </H2>
          <p className="w-75 text-center text-md-left">
            Píšeme o&nbsp;tom, co aktuálně hýbe on‑line a&nbsp;e‑commerce
            světem. Dělíme se o&nbsp;naše znalosti a&nbsp;zkušenosti. Přiblížíme
            vám, na čem pracujeme a&nbsp;jak to v&nbsp;igloo chodí. Sledujte náš
            blog nebo se rovnou přihlaste k&nbsp;odběru novinek.
          </p>
          <div className="d-md-none text-center mb-5 mt-3">
            <Button to="/blog" style={ButtonStyle.Primary}>
              Číst
            </Button>
          </div>
        </Heading>
      </HeadingWrapper>

      <Container fluid>
        <Row>
          <BlogWrapper xs={12} sm={6} md={12} className="mx-auto p-0">
            <BlogWrapperContent className="mx-auto pt-5 d-flex justify-content-center">
              {rss.slice(0, 3).map((article, index) => (
                <BlogInnerWrapper
                  key={index}
                  xs={10}
                  md={4}
                  xxl={3}
                  className="mb-3 pl-md-3 pr-md-3 pl-xl-5 pr-xl-5"
                >
                  <RssBlogInner className="d-flex flex-column mb-4">
                    <RssBlogTitle>
                      <div>
                        <h4 className="text-center text-md-left">
                          <CustomAnchor href={article.link}>
                            {article.title}
                          </CustomAnchor>
                        </h4>
                        <div className="pb-3">
                          <PenguinHead width={28} height={31} />
                          <span className="pl-2 font-weight-bold">
                            {article.creator}
                          </span>
                        </div>
                      </div>
                    </RssBlogTitle>
                    <Text
                      line={6}
                      element="p"
                      truncateText="…"
                      text={article.content
                        .substr(0, article.content.indexOf("</p>"))
                        .replace(/<\/?[^>]+(>|$)/g, "")
                        .replace(/&#160;/g, " ")}
                    />
                  </RssBlogInner>
                </BlogInnerWrapper>
              ))}
            </BlogWrapperContent>
          </BlogWrapper>
        </Row>
      </Container>
    </div>
  )
}

export default BlogSection
